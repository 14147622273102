import React from 'react'
import styled from 'styled-components'
import { FaChevronRight } from "react-icons/fa"
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import TypeTag from '../blocks/TypeTag'

const FeaturedBlog = ({featured}) => {

  return (
    <Link to={`/blog/${featured.slug}`}>
      <Item>
        <div className='imgWrapper'>
          {featured.image && <GatsbyImage image={featured.image?.gatsbyImageData} alt={featured.image?.title}/>}
        </div>
        <Text>        
          <TypeTag item={featured.type} background={"rgb(244 245 246)"}>
            {featured.isShowDate && 
							<div className='date'>
								<p>• {featured.date}</p>
							</div>
						}
          </TypeTag>

          <h2>
            {featured.heading}
          </h2>
          <p className='subHeader'>
            {featured.subHeader}
          </p>
          <div className="itemArrow">
            <p>Read Article</p>
            <div>
              <FaChevronRight />
            </div>
          </div>
        </Text>
      </Item>
    </Link>
	)
}
const Text = styled.div`
  width: 32%;
	@media screen and (max-width: 991px) {
		width: 100%;
	};

  .date {
    font-size: 14px; 
  }
  .itemArrow {
		display: flex;
		align-items: center;
		p {
			font-size: 16px;
		};
		div {
			padding: 0 10px; 
			display: flex; 
			align-items: center; 
			font-size: 16px;
			transition: 0.5s;
		}
	}
  h2 {
		font-size: 28px; 
		margin: 10px 0; 
		@media screen and (max-width: 991px) {
			width: 100%; 
			font-size: 20px; 
		};

	}
  .subHeader {
    font-weight: 400;
		font-size: 20px; 
		margin: 10px 0; 
		@media screen and (max-width: 991px) {
			display: none;
		};
  }
`

const Item = styled.div`
  margin-bottom: 30px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
	cursor: pointer;
	padding: 0 30px;
	@media screen and (max-width: 991px) {
		flex-direction: column; 
		justify-content: center;
		align-items: flex-start;
	};
	.gatsby-image-wrapper {
		border-radius: 8px;
	};
	.imgWrapper {
		width: 65%; 
		height: auto; 
		margin: 0 0 10px 0;
		@media screen and (max-width: 991px) {
			width: 100%; 
		};

		img {
			transition: 0.5s;
      ${'' /* width: 65%;  */}
			height: auto; 
			object-fit: cover;
			border-radius: 8px;
			@media screen and (max-width: 991px) {
				width: 100%; 
			};

		};
	};
	&:hover {
		color: rgb(38 75 101);
		.gatsby-image-wrapper {
			border-radius: 8px;
		};
		img {
			transform: scale(1.05);
			transition: 0.5s;
		};
		.itemArrow {
			div {
				padding: 0 20px;
				transition: 0.5s;
			}
		}
	}
`

export default FeaturedBlog