import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../layouts/layout'
import Seo from '../components/seo'
import BlueHeader from '../components/UI/Header/BlueHeader'
import FiltersAndBlogs from '../components/ForBlogPage/FiltersAndBlogs'
import { blog } from '../support/structuredDataConfig/blog'

const RootIndex = ({ path }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Seo
        title="Our Blog - Personal Finance Education"
        description="Your go-to resource hub for all things finance education and inspiration. Check out our blog to improve your financial literacy and learn more today!"
        path={path}
        structuredData={blog(
          'SocietyOne blog and resources',
          `${site.siteMetadata.siteUrl}${path}`
        )}
      />
      <BlueHeader headerText={'The SocietyOne Blog'}>
        {'Your go-to resource for all things personal finance.'}
      </BlueHeader>
      <FiltersAndBlogs />
    </Layout>
  )
}

export default RootIndex
