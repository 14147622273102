import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import makeStyles from '@mui/styles/makeStyles'
import { useFlexSearch } from 'react-use-flexsearch'

import BlogItem from './BlogItem'
import FeaturedBlog from './FeaturedBlog'
import ContentContainer from '../UI/ContentContainer'
import FilterButton from '../../components/UI/Buttons/FilterButton'
import { useResetState, getPaginationArray, filterByBlogsType } from '../../utils/blogRelated'
import BlogItemForSearch from './BlogItemForSearch'

// this is for align to right for AccordionSummary of Mui 
const useStyles = makeStyles(theme => ({
  customAccordionSummary: {
    justifyContent: "flex-end",
    margin: "0",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",

    },
  }
}))

const FiltersAndBlogs = () => {
  const data = useStaticQuery(graphql`
    query FiltersAndBlogsQuery {
      allContentfulPageBlogPost(
        filter: {type: {ne: "Credit Score Club"}}
        sort: {fields: date, order: DESC}
      ) {
        nodes {
          isShowDate
          heading
          date(formatString: "MMM Do, YYYY")
          slug
          type
          subHeader
          content {
            childMarkdownRemark {
              html
            }
          }
          heroImage {
            gatsbyImageData(placeholder:NONE)
            title
          }
          image {
            gatsbyImageData(placeholder:NONE)
            title
          }
          featured
        }
      }
      localSearchBlogSearch {
        index
        store
      }
    }
  `)
  
  // this part is for the filter
  const result = data.allContentfulPageBlogPost.nodes
  const typeArray = ["All", ...new Set(result.map(item => item.type))]
  const initState = {}
  typeArray.forEach(key => { initState[key] = false })
  const [typeState, setTypeState] = useState('All')
  
    // customized hooks to reset the state
  const [btnState, setBtnState, resetBtnState] = useResetState(initState)
  
  const handleClick = useCallback((type) => {
    resetBtnState()
    setBtnState({
      [type]: true
    })
    setTypeState(type)
  }, [setBtnState, setTypeState, resetBtnState])

  let featured
  let blogListWithoutFeatured = []
  for (let i = 0; i < result.length; i++) {
    // if "featured" field in CMS is set to TRUE
    if (result[i].featured === true) {
      featured = result[i]
    } else {
      blogListWithoutFeatured.push(result[i])
    }
  }

  // featured blog is not included in filteredBlogs list
  const filteredBlogs = filterByBlogsType( typeState, blogListWithoutFeatured )
  
  // this part is for search bar
  const index = data.localSearchBlogSearch.index
  const store = data.localSearchBlogSearch.store
  const handleChange = (e) => {
    setInputState(e.target.value)
    if (typeof window !== 'undefined') {
      window.location.href = `#${document.getElementById("searchInBlog").value}`
    }
  }
  const [inputState, setInputState] = useState('')
  const searchedResults = useFlexSearch(inputState, index, store)
  const filteredSearchedResults = filterByBlogsType( typeState, searchedResults )
  
  // this is for pagination with filter but without searching
  const postsPerPage = 24
  const numPagesFilteredBlogs = Math.ceil(filteredBlogs.length / postsPerPage)
  const postArrayForFilteredBlogs = getPaginationArray(filteredBlogs, numPagesFilteredBlogs, postsPerPage) // separate the blogs array as this: [[], [], [], ...]

  // this is for pagination with filter but with searching
  const numPagesFAndSResults = Math.ceil(filteredSearchedResults.length / postsPerPage)
  const postArrayForFAndSResults = getPaginationArray(filteredSearchedResults, numPagesFAndSResults, postsPerPage) // separate the blogs array as this: [[], [], [], ...]

  // here to use the styles
  const classes = useStyles();

  if (typeof window !== 'undefined') {
    window.onscroll = function() {
      if(inputState === '') {
        for (let i = 1; i <= numPagesFilteredBlogs; i++) {
          var scrollTop = document.getElementById("page" + i).getBoundingClientRect().top;
          console.log(`page${i}` + scrollTop);
          if(scrollTop < 40 && scrollTop > -100) {
            window.location.href = `#page${i}`
          }
        }
      }

      if(inputState !== '') {
        if (document.getElementById("results-page1").getBoundingClientRect().top > 200) {
          window.location.href = `#${inputState}`
        } else {
          for (let i = 1; i <= numPagesFAndSResults; i++) {
            var scrollTopForFAndSResults = document.getElementById("results-page" + i).getBoundingClientRect().top;
            console.log(`results-page${i}` + scrollTopForFAndSResults);
            if(scrollTopForFAndSResults < 40 && scrollTopForFAndSResults > -100) {
              window.location.href = `#${inputState}&results-page${i}`
            }
          }
        }
      }
    }
  }
  return (
		<ContentContainer>
      <FeaturedBlog featured={featured}/>
      <SearchAndFilters>
        <SearchBar>
          <Input>
            <input id='searchInBlog' type='text' placeholder='Search...' value={inputState} onChange={handleChange}/>
          </Input>
        </SearchBar>
        <Filter>
          <Accordion disableGutters style={{ boxShadow: 'none', borderBottom: 'none' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              style={{ padding: '0', margin: '0' }}
              classes={{ content: classes.customAccordionSummary }}
            >
              <Header>
                Filters
              </Header>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '0', margin: '0' }}>
              {typeArray.map((item, index) => {
                return (
                  <FilterButton key={index} isClicked={btnState[item]} onClick={() => handleClick(item)}>
                    {item}
                  </FilterButton>
                )
              })}
            </AccordionDetails>
          </Accordion>
        </Filter>
      </SearchAndFilters>
      <Hr />
      <div>
        {inputState === '' ? 
          <BlogsAndPagination>
            {postArrayForFilteredBlogs.map((blogsArray, index) => 
              <BlogsWrapper id={`page${index + 1}`} key={index}>
                {blogsArray.map((blog, i) => <BlogItem blog={blog} key={i}/>)}
              </BlogsWrapper>
            )}
          </BlogsAndPagination>
          :
          <BlogsAndPagination>
            {postArrayForFAndSResults.map((blogsArray, index) => 
              <BlogsWrapper id={`results-page${index + 1}`} key={index}>
                {blogsArray.map((result, i) => result.heroImage && <BlogItemForSearch heading={result.heading} date={result.date} isShowDate={result.isShowDate} slug={result.slug} type={result.type} heroImage={result.heroImage} heroImageTitle={result.heroImageTitle} key={i}/>)}
              </BlogsWrapper>
            )}
          </BlogsAndPagination>

        }
      </div>
    </ContentContainer>
  )
}

const BlogsAndPagination = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const BlogsWrapper = styled.div`
  padding: 0 30px;
	margin: 40px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px 20px;
  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
	};
  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0;
	};

`
const Hr = styled.hr`
  background-color: rgba(225 225 225); 
  height: 1px; 
  border: none; 
  width: 100%;
  margin-top: 20px;
`
const SearchAndFilters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 30px;
	@media screen and (max-width: 600px) {
		flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0 10px;
	};
`
const SearchBar = styled.div`
  width: 40%;
	@media screen and (max-width: 600px) {
    width: 100%;
	};
` 

const Filter = styled.div`
  width: 50%;
  @media screen and (max-width: 600px) {
    width: 100%;
    margin-top: 10px;
	};
`
const Header = styled.p`
  color: rgb(38 75 101);
  padding: 0; 
  margin: 10px 0; 
  font-size: 18px; 
  @media screen and (max-width: 480px) {
    font-size: 16px; 
  };
`

const Input = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width:100%;
  input {
    width: 100%;
    height: 46px;
    padding: 4px 24px;
    border-radius: 30px;
    border: 1px solid rgb(225 225 225);
    font-size: 18px;
    @media screen and (max-width: 600px) {
      height: 36px;
      font-size: 14px;
    };

  }
` 
export default FiltersAndBlogs