import React from 'react'
import styled from 'styled-components'
import { FaChevronRight } from "react-icons/fa"
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import TypeTag from '../blocks/TypeTag'

const BlogItemForSearch = ({heading, date, isShowDate, slug, type, heroImage, heroImageTitle}) => {

	return (
		<Link to={`/blog/${slug}`}>
			<Item>
				<div className='imgWrapper'>
					{heroImage && <GatsbyImage image={heroImage} alt={heroImageTitle}/>}
				</div>
				<TypeTag item={type} background={"rgb(244 245 246)"}>
					{isShowDate &&
						<div className='date'>
							<p>• {date}</p>
						</div>
					}
				</TypeTag>

				<h2>
					{heading}
				</h2>
				<div className="itemArrow">
					<p>Read Article</p>
					<div>
						<FaChevronRight />
					</div>
				</div>
			</Item>
		</Link>
	)
}

const Item = styled.div`
	cursor: pointer;
	max-width: 384px;
	@media screen and (max-width: 991px) {
		width: 45vw;
		margin: 0 auto;
	};
	@media screen and (max-width: 480px) {
		width: 80vw;
		margin: 0 auto;
	};

	.gatsby-image-wrapper {
		border-radius: 8px;
	};
	.date {
		font-size: 14px; 
	}
	.imgWrapper {
		max-width: 384px;
		height: 230px; 
		margin: 0 0 10px 0;
		@media screen and (max-width: 991px) {
			width: 100%; 
		};

		img {
			transition: 0.5s;
			max-width: 384px;
			height: 230px;
			object-fit: cover;
			border-radius: 8px;
			@media screen and (max-width: 991px) {
				width: 100%; 
			};

		};
	};

	.itemArrow {
		display: flex;
		align-items: center;
		p {
			font-size: 16px;
			font-weight: 400;
		};
		div {
			padding: 0 10px; 
			display: flex; 
			align-items: center; 
			font-size: 16px;
			transition: 0.5s;
		}
	};
	&:hover {
		color: rgb(38 75 101);
		.gatsby-image-wrapper {
			border-radius: 8px;
		};
		img {
			transform: scale(1.05);
			transition: 0.5s;
		};
		.itemArrow {
			div {
				padding: 0 20px;
				transition: 0.5s;
			}
		}
	}
	h2 {
		font-size: 28px; 
		margin: 10px 0; 
		max-width: 384px;
		@media screen and (max-width: 991px) {
			width: 30vw; 
			font-size: 20px; 
		};
		@media screen and (max-width: 991px) {
			width: 100%; 
			font-size: 20px; 
		};

	}
`

export default BlogItemForSearch
